@import "dashlite_variables";

body {
  overflow: auto !important;
}
.pac-container {
  z-index: 99999 !important;
}

.react-datepicker-popper {
  z-index: 1001 !important;
}

.form-input-field {
  padding: 0.63rem 1rem !important;
}
.spinner {
  display: block;
  position: fixed;
  z-index: 1031; /* High z-index so it is on top of the page */
  top: 50%;
  right: 50%; /* or: left: 50%; */
  margin-top: -20px; /* half of the elements height */
  margin-right: -20px; /* half of the elements width */
}
.margin-top_1 {
  margin-top: 1.8rem !important;
}

.top-n5 {
  top: -5px !important;
}
.right-14 {
  right: 14px !important;
}
.custom-solid-btn {
  &-lg {
    padding: 11px 24px !important;
    color: white !important;
    background-color: $blue-100 !important;
    border: none !important;
    cursor: pointer;
    position: relative;
    letter-spacing: 0.02em;

    align-items: center;
    // padding: 11px 24px ;
    font-weight: 500;
    border-radius: 4px;
    line-height: 20px !important;
    font-weight: 700;
    box-shadow: 0px 7px 12px 1px rgb(123 104 238 / 50%) !important;
    &:hover {
      background-color: #006eff !important;
      box-shadow: 0px 7px 12px 1px rgb(29 84 152 / 48%) !important;
    }
  }
  &-md {
    padding: 7px 18px !important;
    color: white !important;
    background-color: $blue-100 !important;
    border: none !important;
    cursor: pointer;
    position: relative;
    letter-spacing: 0.02em;

    align-items: center;
    // padding: 11px 24px ;
    font-weight: 500;
    border-radius: 4px;
    line-height: 20px !important;
    font-weight: 700;
    box-shadow: 0px 7px 12px 1px rgb(123 104 238 / 50%) !important;
    &:hover {
      background-color: #006eff !important;
      box-shadow: 0px 7px 12px 1px rgb(29 84 152 / 48%) !important;
    }
  }
  &-sm {
    padding: 7px 18px !important ;
    color: white !important;
    background-color: #7b68ee !important;
    border: none !important;
    cursor: pointer;
    position: relative;
    letter-spacing: 0.02em;

    align-items: center;
    // padding: 11px 24px ;
    font-weight: 500;
    border-radius: 4px;
    line-height: 20px !important;
    font-weight: 700;
    box-shadow: 0px 7px 12px 1px rgb(123 104 238 / 50%) !important;
    &:hover {
      background-color: #006eff !important;
      box-shadow: 0px 7px 12px 1px rgb(29 84 152 / 48%) !important;
    }
  }
}

// #user-detail-block{
//   display:block !important
// }

.carer-detail-section .card-inner .nav-tabs-card {
  // margin-right: 0px !important;
}
.simplebar-content-wrapper,
.simplebar-scroll-content {
  background-color: white !important;
  position: relative !important;
  z-index: 55;
}

.DSsoU {
  min-height: 48px !important;
}

.carer-table > :not(caption) > * > * {
  padding: 0.5rem 1.5rem 0.5rem 0px !important;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  vertical-align: middle !important;
}
.nk-upload-item .nk-upload-action .btn-icon {
  min-width: 40px;
  min-height: 40px;
}
.carer-table {
  thead tr:last-child th {
    border-top: none !important;
    vertical-align: middle !important;
    &:last-child {
      padding-right: 0px !important;
    }
  }
  tbody tr:has(.text-end) td {
    border-top: none !important;
    vertical-align: middle !important;
    &:last-child {
      padding-right: 0px !important;
    }
  }
  .btn-icon .icon {
    width: 28px !important;
  }
  .btn-icon .icon {
    font-size: 14px !important ;
    line-height: normal !important;
  }
}

// data table css end--------->
.country-list li.country {
  width: "100%" !important;
  display: block !important;

  @media (min-width: 576px) {
    width: "100%" !important;
  }
  @media (min-width: 992px) {
    width: "100%" !important;
  }
}
.nk-block-head {
  position: sticky !important;
  top: 65px !important;
  background-color: #f5f6fa !important;
  padding-bottom: 0.75rem !important;
  z-index: 51;
  padding-top: 12px !important;
}
.nk-content {
  padding-top: 20px !important;
}
.invalid {
  color: #e85347;
  font-size: 11px;
  font-style: italic;
}
.rdt_TableCol .rdt_TableCol_Sortable {
  justify-content: left !important;
  gap: 5px;
}
.rounded-circle {
  width: 40px;
  height: 40px;
}
.sucess {
  color: #1ee0ac;
}

.table-edit-btn {
  height: 38px !important;
  width: 38px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
// user avtar--------------------------------------------------------->
.user-avatar-sm,
.user-avatar.sm {
  height: 32px !important;
  width: 32px !important;
  font-size: 12px !important;
}
.user-avatar-md,
.user-avatar.md {
  height: 60px !important;
  width: 60px !important;
  font-size: 18px !important;
}
// data Table--------------------------------------------------------->
.rdt_Table {
  margin: 0px !important;
  overflow-x: auto;
  border: none !important;
}
.rdt_TableHeadRow {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.rdt_TableRow {
  min-height: 74px !important;
  font-size: 14px !important;
  &:hover {
    background: #f8f9fc !important;
    box-shadow: 0 2px 15px -4px rgba(44, 117, 255, 0.4) !important;
  }
  &:last-child {
    border-radius: 10px;
  }
}
.date-range-picker {
  flex-wrap: nowrap !important;
  // display: flex;
}

.overflow-visiable {
  overflow: visible !important;
}

.rdt_TableCol .rdt_TableCol_Sortable {
  width: fit-content !important;
  gap: 5px !important;
}

.left-header {
  justify-content: flex-start !important;
  gap: 5px !important;
}

.dataTables_wrapper {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px !important;
}
.right-header {
  justify-content: flex-end !important;
  gap: 5px !important;
}
// country-list------------------------------------------------------->
.country-list {
  flex-wrap: nowrap !important;
  flex-direction: column !important;
  width: 210px !important;
}
@media (min-width: 576px) {
  .country-list li {
    width: 100% !important;
  }
}
// transction ui------------------------------------------------------>
.transaction-ui {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  .rdt_TableCol:not(:last-child),
  .rdt_TableCell:not(:last-child) {
    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
  .rdt_TableCell {
    padding: 0px !important;
  }
  .form-control {
    border: 1px solid #006eff00 !important;
  }
  .form-control:focus {
    color: #3c4d62;
    background-color: #fff;
    border: 1px solid #006eff !important;
    // border-color: #006eff !important;
    border-radius: 0px;
    outline: 0;
    box-shadow: none !important;
  }
  .form-control-select {
    width: 100%;
  }
  .rdt_TableRow {
    min-height: 52px !important;
  }

  .form-control {
    border: 1px solid #006eff00 !important;
  }
  .form-control:focus {
    color: #3c4d62;
    background-color: #fff;
    border: 1px solid #006eff !important;
    // border-color: #006eff !important;
    border-radius: 0px;
    outline: 0;
    box-shadow: none !important;
  }
  .form-control-select {
    width: 100%;
  }
  .rdt_TableRow {
    min-height: 52px !important;
  }

  // new------------->

  .table-rows .nk-tb-col:not(:last-child),
  .nk-tb-head .nk-tb-col:not(:last-child) {
    border-right: 1px solid #dbdfea !important;
  }
  .table-rows .nk-tb-col {
    min-height: 50px !important;
  }
  .table-rows .nk-tb-col:last-child {
    width: 50px !important;
  }
  .table-rows .nk-tb-col {
    padding: 0px !important;
  }
  .css-yk16xz-control,
  .react-select__control {
    border: none !important;
  }
  .css-yk16xz-control,
  .form-control-select {
    min-height: 50px !important;
  }
  .react-select__control--menu-is-open {
    border: 1px solid #006eff !important;
    min-height: 50px !important;
    border-radius: 0px !important;
  }
  .react-select__control--is-focused {
    min-height: 50px !important;
  }
  .form-input-field {
    min-height: 50px !important;
  }
  .form-control-select:after {
    top: 8px !important;
  }
  .nk-tb-head .nk-tb-col .sub-text {
    color: #526484 !important;
  }
  .dropdown {
    display: block !important;
    .btn {
      margin: auto !important;
    }
  }
  .wrong-input {
    border: 1px solid #ff0000 !important;
    border-radius: 0px !important;
  }
  .rigth-input {
    border: 1px solid #ff000000 !important;
  }
  .wrong-input:focus {
    border: 1px solid #ff0000 !important;
    border-radius: 0px !important;
  }
  .invalid {
    display: none !important;
  }
  .input-group-text {
    border-radius: 0px !important;
  }
}
.h-36 {
  height: 36px !important;
}
.top-n12 {
  top: -12px !important;
}
.monies-in-btn {
  position: absolute;
  right: 186px;
  width: 136px;
}
.monies-out-btn {
  position: absolute;
  right: 64px;
  width: 136px;
}
.react-datepicker-popper {
  min-width: 293px !important;
}

.margin {
  &-t {
    &-n3 {
      margin-top: -3px;
    }
  }
}

.min {
  &-h {
    &-120 {
      min-height: 120px !important;
    }
  }
}
.cursor-pointer {
  cursor: pointer !important;
}

.width-300px_-39px {
  width: calc(300px - 39px) !important;
}
.resident-block {
  border: 1px solid #1ee0ac !important;
  padding: 8px 8px !important;
  border-radius: 4px !important;
  background-color: white !important;
  min-width: 120px !important;
  display: flex;
  flex-direction: column;
}
.rdt_TableHeadRow {
  background-color: $blue-100 !important;
  border-radius: 0px !important;
  // margin: 0px;
  // padding-top: 5px;
  // padding-bottom: 5px;
  .rdt_TableCol_Sortable {
    color: white !important;
  }
}
// dropdown-------------->
.react-select__single-value {
  font-family: "Poppins", sans-serif !important;
}
.link-list-opt {
  .success-box {
    background-color: #1ee0ac !important;
    span {
      color: white !important;
    }
    &:hover {
      background-color: white !important;
      span {
        color: #1ee0ac !important;
      }
    }
  }
  .danger-box {
    background-color: #e85347 !important;
    span {
      color: white !important;
    }
    &:hover {
      background-color: white !important;
      span {
        color: #e85347 !important;
      }
    }
  }
  .warning-box {
    background-color: #f4bd0e !important;
    span {
      color: white !important;
    }
    &:hover {
      background-color: white !important;
      span {
        color: #f4bd0e !important;
      }
    }
  }
}

// Verticale Line

.border-card-end {
  border-bottom: 1px solid #dbdfea; /* Adjust the border properties as needed */
  // padding-bottom: 10px;
}
.fs-span {
  font-size: 10px;
  color: #006eff;
  cursor: pointer;
}
.fs-12 {
  font-size: 12px;
}
.custom-card-height {
  height: 100%; /* Adjust the height as needed */
}
.align-v-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.table-container {
  // max-height: 300px;
  overflow-y: auto;
}

.information-icon {
  font-size: 18px;
  cursor: pointer;
}
.audit-trail-seprater {
  border-right: 1px solid #dddddd !important;
}
.pre-reco-button {
  background-color: #09c0db;
  color: #ffffff;
}
.reco-text {
  color: #f5f6fa;
}
.pre-reco-button:hover {
  background-color: #09c0db;
  color: #ffffff;
}
.date-text {
  color: black !important;
}

.date-text::placeholder {
  color: #3c4d62 !important;
}
.date-picker-height {
  height: 50px !important;
}

.table-scroll-body {
  .rdt_TableBody {
    max-height: 600px !important;
    overflow: auto !important;
  }
}
.mt-20px {
  margin-top: 20px !important;
}
.bg-green {
  background-color: #1ee0ac;
}

.w-66 {
  width: 66%;
  @media (max-width: 1530px) {
    // Rules for smaller screens
    width: 60%;
  }
  @media (max-width: 1318px) {
    // Rules for smaller screens
    width: 55%;
  }
  @media (max-width: 982px) {
    // Rules for smaller screens
    width: 35%;
  }
}
.reco-header {
  .nk-block-head {
    padding: 0 !important;
  }
}

.me-10px {
  margin-right: 6px;
}
